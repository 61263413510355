import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ReactGA from 'react-ga';

const StyledGridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flex: '1 1 50%',
    },
    [theme.breakpoints.down('md')]: {
        flex: '1 1 100%',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const TaxiLotDirectionsTab = () => {
    useEffect(() => {
        ReactGA.pageview('/taxi-lot-directions');
    }, []);

    const images = [
        "https://undelay-dashboard.s3.us-east-1.amazonaws.com/images/Dallas+Love+Field+Lot+1.png",
        "https://undelay-dashboard.s3.us-east-1.amazonaws.com/images/Dallas+Love+Field+Lot+2.png",
    ];

    return (
        <Box sx={{ margin: 2 }}>
            <Grid container sx={{ justifyContent: 'center', background: 'white' }}>
                {images.map((src, index) => (
                    <StyledGridItem item key={index}>
                        <Box
                            component="img"
                            src={src}
                            alt={`Taxi Lot ${index + 1}`}
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </StyledGridItem>
                ))}
            </Grid>
        </Box>
    );
};

export default TaxiLotDirectionsTab;